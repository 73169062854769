const LeadIdEvents = ['saveLeadDetail', 'pageLoad']

// Event Definitions
export const getLeadIdEventObj = (
  event: string,
  genericEventObj: any,
  override?: any
) => {
  const updatedEventObj = {
    ...genericEventObj,
  }

  if (LeadIdEvents.includes(event)) {
    if (event === 'saveLeadDetail') {
      override.salesforceID &&
        (updatedEventObj.salesforceID = override.salesforceID)
      override.username && (updatedEventObj.username = override.username)
      override.passthroughCode &&
        (updatedEventObj.passthroughCode = override.passthroughCode)
    } else if (event === 'pageLoad') {
      override.leadAppStage &&
        (updatedEventObj.leadAppStage = override.leadAppStage)
      override.leadAppSubStage &&
        (updatedEventObj.leadAppSubStage = override.leadAppSubStage)
      override.label && (updatedEventObj.label = override.label)
      override.description &&
        (updatedEventObj.description = override.description)
      override.value && (updatedEventObj.value = override.value)
    }
  }

  return updatedEventObj
}

export default getLeadIdEventObj
