import {getTimeStamp} from './common'
import {getUserId, getTrackingId, getSite, getLocation} from './crossTracking'

const getEventObj = (
  event: string,
  element?: string | null,
  label?: string | null,
  value?: string | null,
  description?: string | null
) => {
  return {
    userId: getUserId(),
    trackingId: getTrackingId(),
    website: getSite(),
    path: getLocation(),
    event: event,
    element: element ? element : null,
    label: label ? label : null,
    value: value ? value : null,
    description: description ? description : null,
    timestamp: getTimeStamp(),
  }
}

export {getEventObj}
