import { browserName, deviceDetect } from 'react-device-detect';
import { isArray, isEmpty } from 'lodash';

import DeviceDetector from 'device-detector-js';
import Filter from 'bad-words';

const deviceDetector = new DeviceDetector();
const filter = new Filter();
const isBrowser = typeof window !== 'undefined';
const FormUtils = {
  cleansePhone: (num) => {
    return num.replace(/[^0-9]/g, '');
  },

  getURL: () => {
    return typeof window !== 'undefined' ? window.location.href : '';
  },

  cleanUrlFragment: (urlString) => {
    return urlString.split('#')[0];
  },

  getDeviceDetect: () => {
    return deviceDetect();
  },

  getOS: () => {
    let device = deviceDetect();
    return device.osName ? device.osName : '';
  },

  getOSVersion: () => {
    let device = deviceDetect();
    return device.osVersion ? device.osVersion : '';
  },

  getBrowserName: () => {
    return browserName;
  },

  getDeviceDetails: () => {
    if (isBrowser) {
      const deviceDetails = deviceDetector.parse(deviceDetect().userAgent);
      return deviceDetails.device;
    }
    return {};
  },

  getWindow: () => {
    if (isBrowser) {
      return global.window;
    }
    return null;
  },

  getDocument: () => {
    if (isBrowser) {
      return global.document;
    }
    return null;
  },

  getAge: (dateString) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  },

  getCurrentDate: () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    let h = today.getHours();
    let m = today.getMinutes();
    let s = today.getSeconds();

    return mm + '/' + dd + '/' + yyyy + ' - ' + h + ':' + m + ':' + s;
  },

  cleanString: (inputString) => {
    if (!isEmpty()) return filter.clean(inputString).replaceAll('*', '');
    return inputString;
  },

  replaceSemicolonWithComma: (inputString) => {
    const outputString = inputString.replace(/;/g, ',');
    return outputString;
  },

  scrollToTop: () => {
    if (global.window) {
      setTimeout(() => {
        global.window.scrollTo(0, 0);
      }, 200);
    }
  },

  isQueryParamEmpty: (queryParamValue) => {
    if (
      isEmpty(queryParamValue) ||
      queryParamValue === '' ||
      queryParamValue === 'undefined' ||
      queryParamValue === 'null'
    )
      return true;
    return false;
  },

  queryParamArrayCheck: (queryParamValue) => {
    if (isArray(queryParamValue) && queryParamValue.length != 0) {
      if (
        FormUtils.isQueryParamEmpty(queryParamValue[queryParamValue.length - 1])
      ) {
        return '';
      }
      return queryParamValue[queryParamValue.length - 1];
    } else {
      return queryParamValue;
    }
  },
};

export default FormUtils;
