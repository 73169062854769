const quotingToolEvents = [
  'savePlanSuccessful',
  'savePlanFailed',
  'removeSavedPlanSuccessful',
  'removeSavedPlanFailed',
  'viewMoreCardDetails',
  'viewLessCardDetails',
  'removeAddedPlanForComparison',
  'addCardForComparison',
  'viewCompletePlanDetails',
  'confirmEnroll',
  'confirmApply',
  'rejectEnroll',
  'rejectApply',
  'comparePlans',
]

// Event Definitions
export const getQuotingToolEventObj = (
  event: string,
  genericEventObj: any,
  override?: any
) => {
  const updatedEventObj = {
    ...genericEventObj,
  }

  if (quotingToolEvents.includes(event)) {
    if (
      event === 'savePlanSuccessful' ||
      event === 'savePlanFailed' ||
      event === 'removeSavedPlanSuccessful' ||
      event === 'removeSavedPlanFailed' ||
      event === 'viewMoreCardDetails' ||
      event === 'viewLessCardDetails' ||
      event === 'removeAddedPlanForComparison' ||
      event === 'addCardForComparison' ||
      event === 'viewCompletePlanDetails' ||
      event === 'confirmEnroll' ||
      event === 'confirmApply' ||
      event === 'rejectEnroll' ||
      event === 'rejectApply'
    ) {
      override.planType && (updatedEventObj.planType = override.planType)
      override.planId && (updatedEventObj.planId = override.planId)
      override.planName && (updatedEventObj.planName = override.planName)
    } else if (event === 'comparePlans') {
      override.planList && (updatedEventObj.planList = override.planList)
    }
  }

  return updatedEventObj
}

export default getQuotingToolEventObj
