const idleTimeEvents = ['idleTime']

// Event Definitions
export const getIdleTimeEventObj = (
  event: string,
  genericEventObj: any,
  override?: any
) => {
  const updatedEventObj = {
    ...genericEventObj,
  }

  if (idleTimeEvents.includes(event)) {
    if (event === 'idleTime') {
      override.interval && (updatedEventObj.interval = override.interval)
    }

    return updatedEventObj
  }
}

export default getIdleTimeEventObj
