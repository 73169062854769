import log from "./log";

const DemographicsEvents = ["organicLead"];

// Event Definitions
export const getDemographicsEventObj = (
  event: string,
  genericEventObj: any,
  override?: any
) => {
  const updatedEventObj = {
    ...genericEventObj,
  };

  if (DemographicsEvents.includes(event)) {
    if (event === "organicLead") {
      override.leadURL && (updatedEventObj.leadURL = override.leadURL);
      override.leadId && (updatedEventObj.leadId = override.leadId);
      override.gid && (updatedEventObj.gid = override.gid);
      override.oldCallbackStatus && (updatedEventObj.oldCallbackStatus = override.oldCallbackStatus);
      override.newCallbackStatus && (updatedEventObj.newCallbackStatus = override.newCallbackStatus);
      override.callbackPercentage && (updatedEventObj.callbackPercentage = override.callbackPercentage);
    }
    log("Sending organicLead EVent: ", updatedEventObj)
  }

  return updatedEventObj;
};

export default getDemographicsEventObj;
