import FormService from '../service/formService';
import { fireEvent } from '../cra';

const addressSectionExitIntentUtils = {
  routeToQuotingTool: () => {

    const data = JSON.parse(sessionStorage.getItem('qtParamsToRedirect'));

    const query = `?pc=${data.pc}&spouse=${data.spouse}&lead_id=${
      data.salesforceLeadID
    }&callInNum=${data.callInNum}&zip=${data.zip}&state=${
      data.stateAbbrv
    }&gender=${data.gender && data.gender.charAt(0).toUpperCase()}&username=${
      data.customerUsername
    }&token=${data.passthroughCode}&forUAT=${data.forUAT}&leadBrand=${data.leadBrand}&nxt=QT-LOGIN`;

    const redirectURL = process.env.GATSBY_REDIRECT_APP + query;

    console.log('decided url: ', redirectURL);
    fireEvent(
      'navigate',
      {
        target: { href: redirectURL },
      },
      {
        description: 'Demographics Address Section Exit Intent',
      }
    );
    fireEvent('saveLeadDetail', {}, {});

    setTimeout(() => {
      if (redirectURL.startsWith('https') || redirectURL.startsWith('http'))
        window.location.href = redirectURL;
      else window.location.href = redirectURL;
    }, 1000)

  },

  exitIntentCallback: () => {
    fireEvent('saveLeadDetail', {}, {});
    const data = JSON.parse(sessionStorage.getItem('qtParamsToRedirect'));
    const requestBody = {
      salesforceLeadID: data.salesforceLeadID,
      customerUsername: data.customerUsername,
      leadAppStage: data.leadAppStage,
      leadAppSubstage: data.leadAppSubstage,
      callbackLogic: 'Exit Intent',
      forUAT: data.forUAT,
    };
    console.log('Update lead during Exit Intent at Address', data);
    FormService.updateQTSalesforceLead(requestBody).then(() => {
      console.log('Salesforce lead updated during Exit intent!');
    });
  },
};

export default addressSectionExitIntentUtils;
