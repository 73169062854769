import { config } from '../config/config'
import { getTimeStamp } from './common'
import {
  getCrossUser,
  getLocation,
  getSessionId,
  getSite,
  getTrackingId,
  getUserId,
  isCrossUser,
} from './crossTracking'
import { getDemographicsEventObj } from './getDemographicsEventObj'
import { getEventObj } from './getEventObj'
import getIdleTimeEventObj from './getIdleTimeEventObj'
import getLeadIdEventObj from './getLeadIdEventObj'
import getMedicare101EventObj from './getMedicare101EventObj'
import getQuotingToolEventObj from './getQuotingToolEventObj'
import log from './log'
import { socket } from './socket'

const genericEvents = [
  'click',
  'hover',
  'initiateCall',
  'sendEmail',
  'zipSubmit',
  'textInput',
  'radioInput',
  'checkboxInput',
  'navigate',
  'exit',
  'phoneInput',
  'loginAttempt',
  'loginSuccessful',
  'loginFailed',
  'logoutSuccessful',
  'logoutFailed',
  'forgotPassword',
  'popupOpen',
  'popupClose',
  'changePage',
  'switchInput',
  'starRatingInput',
  'videoLoaded',
]

const specializedEvents = [
  'action',
  'getTimers',
  'navigate',
  'idle',
  'idleTime',
]

// Emit connect event when socket is connected
socket.on('connect', () => {
  getCrossUser()
  try {
    socket.emit(
      isCrossUser ? 'crossUserConnected' : 'newUserConnected',
      getEventObj(isCrossUser ? 'crossUserConnected' : 'newUserConnected')
    )
  } catch (e) {
    log('Socket not open')
  }
})

export const fireVideoEvent = (event: string, payload?: any) => {
  if (event === 'trackVideo') {
    try {
      socket.emit('trackVideo', {
        userId: getUserId(),
        trackingId: getTrackingId(),
        sessionId: getSessionId(),
        website: getSite(),
        path: getLocation(),
        event: event,
        videoName: payload.videoName || null,
        totalSeconds: payload.totalSeconds,
        intervalDuration: payload.intervalDuration,
        round: payload.round,
        secondPlaying: payload.secondPlaying,
        timestamp: getTimeStamp(),
      })
    } catch (e) {
      log('Socket not open')
    }
  }
}

// Event Definitions and Emissions
export const fireEventUtil = (event: string, payload?: any, override?: any) => {
  const element = (payload && payload.target && payload.target.nodeName) || null
  const label =
    (override && override.label) ||
    (payload && payload.target && payload.target.innerText) ||
    null
  const value =
    (override && override.value && override.value) ||
    (payload && payload.target && payload.target.value)
  const description = (override && override.description) || null

  let genericEventObj = getEventObj(event, element, label, value, description)

  if (genericEvents.includes(event) && !specializedEvents.includes(event)) {
    log(`Generic ${event}: `, genericEventObj)
    try {
      socket.emit('event', genericEventObj)
    } catch (e) {
      log('Socket not open')
    }
  } else if (specializedEvents.includes(event)) {
    log('Firing specialized event: ', event)
    if (event === 'action') {
      payload.handleAction({
        show: true,
        event: 'popup',
        label: payload.label as string,
        path: payload.path as string,
        sessionId: payload.sessionId as string,
        website: payload.website as string,
      })
    }

    if (event === 'getTimers') {
      try {
        socket.emit('getTimers', getEventObj('getTimers'))
      } catch (e) {
        log('Socket not open')
      }
    }

    if (event === 'navigate') {
      const genericPayload = getEventObj('navigate')
      const navigatePayload = {
        ...genericPayload,
        toWebsite: new URL(payload.target.href).origin || null,
        toPath: new URL(payload.target.href).pathname || null,
      }
      try {
        socket.emit('navigate', navigatePayload)
      } catch (e) {
        log('Socket not open')
      }
    }

    if (event === 'idle') {
      const genericPayload = getEventObj(
        'idle',
        element,
        label,
        value,
        description
      )
      const idlePayload = {
        ...genericPayload,
        duration: value,
      }
      try {
        socket.emit('event', idlePayload)
      } catch (e) {
        log('Socket not open')
      }
    } else if (event === 'idleTime') {
      const genericPayload = getIdleTimeEventObj(
        event,
        genericEventObj,
        override
      )
      const idlePayload = {
        ...genericPayload,
        duration: value,
      }
      try {
        socket.emit('event', idlePayload)
      } catch (e) {
        log('Socket not open')
      }
    }
  } else if (event === 'saveLeadDetail' || event === 'pageLoad') {
    const leadIdEventObj = {
      ...getLeadIdEventObj(event, genericEventObj, override),
    }
    log(`${event}: `, leadIdEventObj)
    try {
      socket.emit('event', leadIdEventObj)
    } catch (e) {
      log('Socket not open')
    }
  } else {
    if (config.website === 'Quoting Tool') {
      if (event === 'organicLead') {
        const demographicsEventObj = {
          ...getDemographicsEventObj(event, genericEventObj, override),
        }
        log(`Quoting Tool Specific ${event}: `, demographicsEventObj)
        try {
          socket.emit('event', demographicsEventObj)
        } catch (e) {
          log('Socket not open')
        }
      } else {
        const quotingToolEventObj = {
          ...getQuotingToolEventObj(event, genericEventObj, override),
        }
        log(`Quoting Tool Specific ${event}: `, quotingToolEventObj)
        try {
          socket.emit('event', quotingToolEventObj)
        } catch (e) {
          log('Socket not open')
        }
      }
    } else if (config.website === 'Medicare 101') {
      const medicare101EventObj = {
        ...getMedicare101EventObj(event, genericEventObj, override),
      }
      log(`Medicare 101 Specific ${event}: `, medicare101EventObj)
      try {
        socket.emit('event', medicare101EventObj)
      } catch (e) {
        log('Socket not open')
      }
    } else if (config.website === 'Demographics') {
      const demographicsEventObj = {
        ...getDemographicsEventObj(event, genericEventObj, override),
      }
      log(`Demographics Specific ${event}: `, demographicsEventObj)
      try {
        socket.emit('event', demographicsEventObj)
      } catch (e) {
        log('Socket not open')
      }
    }
  }
}

export default fireEventUtil
