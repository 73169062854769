const medicare101Events = [
  'questionSkipped',
  'questionAnswered',
  'infoRead',
  'infoSkipped',
  'skipToQuotes',
  'formSubmit'
]

// Event Definitions
export const getMedicare101EventObj = (
  event: string,
  genericEventObj: any,
  override?: any
) => {
  const updatedEventObj = {
    ...genericEventObj,
  }

  if (medicare101Events.includes(event)) {
    if (
      event === 'questionSkipped' ||
      event === 'questionAnswered' ||
      event === 'infoRead' ||
      event === 'infoSkipped' ||
      event === 'skipToQuotes'
    ) {
      override.contentId && (updatedEventObj.contentId = override.contentId)
      override.contentTitle &&
        (updatedEventObj.contentTitle = override.contentTitle)
      override.contentSupertext &&
        (updatedEventObj.contentSupertext = override.contentSupertext)
      override.answer && (updatedEventObj.answer = override.answer)
    }
    else if (event === "formSubmit"){
      override.progress && (updatedEventObj.progress = override.progress)
    }
  }

  return updatedEventObj
}

export default getMedicare101EventObj
