import {
  ActionPayloadI,
  PayloadI,
  ThemePayloadI,
} from '../interfaces/ActionPayload'
import React, { useEffect } from 'react'

import Popup from '../components/Popup'
import { config } from '../config/config'
import fireEvent from '../utils/fireEvent'
import log from '../utils/log'
import { socket } from '../utils/socket'
import { useVideoTracking } from '../hooks/useVideoTracking'

interface ActionContextProps {
  children: React.ReactNode
  routeToQuotingTool?: () => void
  idleTimeCallbackQt?: () => void
  idleTimeCallbackM101?: () => void
  exitIntentCallback?: () => void
  leadBrand?: string
}

const ActionContext = React.createContext({
  action: {
    type: '',
    timer: 0,
    path: '',
    content: [] as string[],
    label: '',
    inputType: '',
    inputPlaceholder: '',
    inputAction: '',
  },
  handleAction: (payload: PayloadI) => {},
  resetAction: () => {},
  trackCurrentPage: (pageName: string) => {},
  trackVideo: {
    startTimer: (receivedVideoId: string) => {},
    clearTimer: () => {},
    resetTimer: () => {},
    trackSecondPlaying: (second: number) => {},
  },
  forceExitIntent: () => {},
  suppressExitIntent: () => {},
})

const ActionContextProvider: React.FC<ActionContextProps> = ({
  children,
  routeToQuotingTool,
  idleTimeCallbackM101,
  idleTimeCallbackQt,
  exitIntentCallback,
  leadBrand = '',
}) => {
  const [timers, setTimers] = React.useState<number[]>([])
  const [userPhone, setUserPhone] = React.useState<string>(
    localStorage.getItem('phone') || ''
  )

  const [exitIntentShown, setExitIntentShown] = React.useState(false)
  const [exitIntentFireCount, setExitIntentFireCount] = React.useState(0)
  const [idleTimeShown, setIdleTimeShown] = React.useState(false)

  const [forceExitIntentStatus, setForceExitIntentStatus] =
    React.useState(false)

  const forceExitIntent = () => {
    setForceExitIntentStatus(true)
  }

  const suppressExitIntent = () => {
    setExitIntentFireCount((exitIntentFireCount) => exitIntentFireCount + 1)
  }

  const handleMouseLeave = (e: MouseEvent) => {
    if (
      e.clientY <= 0 ||
      e.clientX <= 0 ||
      e.clientX >= window.innerWidth ||
      e.clientY >= window.innerHeight
    ) {
      // User's cursor has left the website
      if (exitIntentFireCount === 0) {
        log("User's cursor has left the website")
        resetAction()
        setExitIntentShown(true)
        setExitIntentFireCount(exitIntentFireCount + 1)

        // exitIntentCallback && exitIntentCallback();
      }

      if (exitIntentFireCount === 0 && forceExitIntentStatus) {
        setExitIntentShown(true)
        setExitIntentFireCount(exitIntentFireCount + 1)
      }
    }
  }

  // disabling exit intents from CRA

  // useEffect(() => {
  //   if (config.website === 'Medicare 101' || forceExitIntentStatus) {
  //     document.addEventListener('mouseleave', handleMouseLeave)
  //     return () => {
  //       document.removeEventListener('mouseleave', handleMouseLeave)
  //     }
  //   }
  // }, [exitIntentFireCount, exitIntentCallback, forceExitIntentStatus])

  const [action, setAction] = React.useState<ActionPayloadI>({
    type: '',
    timer: 0,
    path: '',
    content: [] as string[],
    label: '',
    inputType: '',
    inputPlaceholder: '',
    inputAction: '',
  })

  const [theme, setTheme] = React.useState<ThemePayloadI>({
    primaryColor: '',
    secondaryColor: '',
  })

  const trackVideo = React.useCallback(useVideoTracking, [])()

  const [currentPage, setCurrentPage] = React.useState('/')
  const timer = React.useRef(0)

  const handleAction = (payload: PayloadI) => {
    setAction({
      ...payload.action,
    })

    setTheme({
      ...payload.theme,
    })
  }

  const resetAction = () => {
    setAction({
      type: '',
      timer: 0,
      path: '',
      content: [],
      label: '',
      inputType: '',
      inputPlaceholder: '',
      inputAction: '',
    })
  }

  const trackCurrentPage = React.useCallback((pageName: string) => {
    setCurrentPage(pageName)
    fireEvent('pageLoad', null, {
      label: `${pageName} Loaded`,
      description: `${pageName} Loaded`,
      value: pageName,
    })
    log('currentPage: ', pageName)
  }, [])

  React.useEffect(() => {
    socket.on('action', (payload) => {
      handleAction(payload)
    })

    socket.on(
      'timers',
      (payload: {
        timers: number[]
        sessionId: string
        website: string
        userId: string
      }) => {
        setTimers(payload.timers)
        log('sessionId: ', payload.sessionId)
        localStorage.setItem('sessionId', payload.sessionId)
      }
    )

    socket.on(
      'phoneInputCompleted',
      (payload: {
        sessionId: string
        website: string
        path: string
        userId: string
        data: any
      }) => {
        log(payload)
        localStorage.setItem('phone', payload.data.data.phone)
        setUserPhone(payload.data.data.phone)
        setAction({
          type: 'popupThankYou',
          timer: 0,
          path: '',
          content: [
            'Our Licenced Agent will reach out to you shortly',
            'For any query, please call on 888 999 12423',
          ],
          label: 'Thank You',
          inputType: '',
          inputPlaceholder: '',
          inputAction: '',
        })
      }
    )

    document.addEventListener('mousemove', resetTimer)
    document.addEventListener('keydown', resetTimer)
    document.addEventListener('scroll', resetTimer)
  }, [])

  const resetTimer = () => {
    timer.current = 0
  }

  React.useEffect(() => {
    fireEvent('getTimers', null, {
      label: `Getting timers for ${currentPage}`,
    })
  }, [currentPage])

  React.useEffect(() => {
    timer.current = 0
    const mainInterval = setInterval(() => {
      timer.current = timer.current += 1
      // log(timer.current)

      const timerExists = timers.find((itr) => itr === timer.current)

      if (timerExists) {
        fireEvent('idle', currentPage, {
          value: timer.current,
        })
      }
      if (timer.current !== 0 && timer.current % 5 === 0)
        fireEvent('idleTime', currentPage, {
          value: timer.current,
          interval: 5,
        })
    }, 1000)

    return () => {
      mainInterval && clearInterval(mainInterval)
    }
  }, [currentPage, timers, userPhone])

  useEffect(() => {
    if (action.type === 'popup' || action.type === 'popupThankYou')
      exitIntentShown && setExitIntentShown(false)
  }, [action])

  return (
    <ActionContext.Provider
      value={{
        action,
        handleAction,
        resetAction,
        trackCurrentPage,
        trackVideo,
        forceExitIntent,
        suppressExitIntent,
      }}
    >
      {children}
      {!idleTimeShown && action.type === 'popup' && (
        <Popup
          action={action}
          theme={theme}
          resetAction={() => {
            resetAction()
            setIdleTimeShown(true)
          }}
          idleTimeCallbackM101={idleTimeCallbackM101}
          idleTimeCallbackQt={idleTimeCallbackQt}
          leadBrand={leadBrand}
        />
      )}
      {userPhone && action.type === 'popupThankYou' && (
        <Popup
          action={action}
          theme={theme}
          resetAction={resetAction}
          leadBrand={leadBrand}
        />
      )}
      {exitIntentShown && (
        <Popup
          action={{
            path: '/',
            timer: 0,
            type: 'popup',
            label: 'Looking for Medicare Plans?',
            content: [
              `Need a faster way to find the perfect Medicare insurance plan? Head straight to our Quoting Tool!`,
            ],
            inputType: '',
            inputPlaceholder: '',
            inputAction: 'exitIntentConfirm',
          }}
          theme={theme}
          resetAction={() => {
            setExitIntentShown(false)
            resetAction()
          }}
          routeToQuotingTool={routeToQuotingTool}
          exitIntentCallback={exitIntentCallback}
          leadBrand={leadBrand}
        />
      )}
    </ActionContext.Provider>
  )
}

export default ActionContextProvider
export { ActionContext }
