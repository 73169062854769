import {io} from 'socket.io-client'
import log from './log'

const socketDevUrl =
  process.env.REACT_APP_SOCKET_URL_DEVELOPMENT ||
  process.env.GATSBY_SOCKET_URL_DEVELOPMENT ||
  process.env.SOCKET_URL_DEVELOPMENT ||
  ''
const socketProdUrl =
  process.env.REACT_APP_SOCKET_URL_PRODUCTION ||
  process.env.GATSBY_SOCKET_URL_PRODUCTION ||
  process.env.SOCKET_URL_PRODUCTION ||
  ''

const socket = io(
  process.env.NODE_ENV === 'development'
    ? (socketDevUrl as string)
    : (socketProdUrl as string)
)

const socketClose = () => {
  socket.off('connect')
  socket.off('disconnect')
  socket.off('pong')
}

socket.on('disconnect', (reason) => {
  log('User disconnected because ' + reason)
})

export {socket, socketClose, io}
