import { ActionPayloadI, ThemePayloadI } from '../interfaces/ActionPayload'
// import freequote from '../images/free-quote.svg'
import { Cross, FreeQuote, Telephone } from '../static'
import React, { useEffect } from 'react'

import { config } from '../config/config'
import fireEvent from '../utils/fireEvent'
import log from '../utils/log'
import styled from 'styled-components'

const Overlay = styled.div`
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: #0000004f;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  padding: 20px;
`

const Wrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  background-color: #ffffff;
  padding: 32px 20px;
  border-radius: 8px;
  max-width: 450px;
  position: relative;

  @media (max-width: 600px) {
    width: 320px;
  }
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ModalHeading = styled.h1<{ centerText: boolean }>`
  color: ${(props) => (props.color ? props.color : '#2f3032')};
  text-align: ${(props) => (props.centerText ? 'center' : 'left')};
  font-size: 24px;
  font-weight: 600;
  margin: 8px 0px;
`

const ModalParagraph = styled.p<{ centerText: boolean }>`
  color: ${(props) => (props.color ? props.color : '#2f3032')};
  text-align: ${(props) => (props.centerText ? 'center' : 'left')};
  margin-top: 16px;
  line-height: 20px;
  font-size: 14px;
`

const InputBox = styled.input<{
  primaryColor?: string
}>`
  border: solid 2px
    ${(props) => (props.primaryColor ? props.primaryColor : '#222c69')};
  padding: 8px 12px;
  margin-top: 2rem;
  border-radius: 32px;
`

const ButtonGroupDiv = styled.div`
  display: flex;
  justify-content: space-between;
  // margin-top: 1.5rem;
  width: 100%;
`

const Button = styled.div<{
  type?: string
  primaryColor?: string
  width?: string
}>`
  background-color: ${(props) =>
    props.type === 'filled'
      ? props.primaryColor
        ? props.primaryColor
        : '#222c69'
      : '#FFFFFF'};
  border-radius: 32px;
  width: ${(props) => props.width || '100%'};
  padding: 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.type === 'outlined'
      ? '#6c6c6c'
      : props.color
      ? props.color
      : '#FFFFFF'};
  transition: all 0.2s;

  border: ${(props) =>
    props.type === 'outlined' ? 'solid 2px #6c6c6c' : 'none'};

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`

const Popup: React.FC<{
  action: ActionPayloadI
  theme: ThemePayloadI
  resetAction: () => void
  routeToQuotingTool?: () => void
  idleTimeCallbackM101?: () => void
  idleTimeCallbackQt?: () => void
  exitIntentCallback?: () => void
  leadBrand?: string
}> = ({
  action,
  theme,
  resetAction,
  routeToQuotingTool,
  idleTimeCallbackM101,
  idleTimeCallbackQt,
  exitIntentCallback,
  leadBrand = '',
}) => {
  const [inputValue, setInputValue] = React.useState('')

  useEffect(() => {
    window.Invoca && window.Invoca.PNAPI.run()
  })

  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handlePhoneSubmit = () => {
    fireEvent('phoneInput', null, {
      value: inputValue,
    })
    resetAction()
  }

  const getContact = () => {
    if (typeof window !== 'undefined') {
      const queryParams = new URLSearchParams(window.location.search)
      const leadBrandValue = queryParams.has('leadBrand')
        ? queryParams.get('leadBrand')
        : leadBrand

      if (leadBrandValue === 'medicareassociation') {
        return '(888) 338-9505'
      }

      return '(888) 335-8996'
    }
    return '(888) 335-8996'
  }

  const exitIntentConfirm = () => {
    fireEvent('exitIntentConfirm', null, {
      // value: inputValue,
    })
    log('Exit Intent Handle Submit for website: ', config.website)
    resetAction()
  }

  return (
    <Overlay>
      <Wrapper>
        <ModalContainer>
          <button
            onClick={resetAction}
            style={{
              marginRight: '20px',
              marginTop: '20px',
              height: '14px',
              position: 'absolute',
              right: '0',
              top: '0',
              backgroundColor: 'Transparent',
              backgroundRepeat: 'no-repeat',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            <img
              src={Cross}
              alt="Free Quote"
              className="modal-head-img object-center mx-auto"
              style={{
                objectPosition: 'center',
                height: '14px',
              }}
            />
          </button>
          <img
            src={FreeQuote}
            alt="Free Quote"
            className="modal-head-img object-center mx-auto"
            style={{
              objectPosition: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <ModalHeading color={theme.primaryColor} centerText={true}>
            {action.label}
          </ModalHeading>
          {action.content.map((itr) => (
            <ModalParagraph centerText={true} key={itr}>
              {itr}
            </ModalParagraph>
          ))}
          {action.inputAction && action.inputAction === 'phoneInput' && (
            <InputBox
              type={action.inputType}
              placeholder={action.inputPlaceholder}
              primaryColor={theme.primaryColor}
              value={inputValue}
              onChange={handleInputValue}
            />
          )}
          <h4
            style={{
              color: '#ba0000',
              textAlign: 'center',
              margin: '24px 0px',
              fontSize: '16px',
              lineHeight: '20px',
            }}
          >
            You are one step away from getting your <br />
            Medicare quotes!
          </h4>
          <ButtonGroupDiv>
            {action.inputAction && action.inputAction === 'phoneInput' && (
              <Button
                width="48%"
                type="filled"
                primaryColor={theme.primaryColor}
                onClick={handlePhoneSubmit}
              >
                Submit
              </Button>
            )}
            {action.inputAction &&
              action.inputAction === 'exitIntentConfirm' && (
                <Button
                  width="100%"
                  type="filled"
                  primaryColor={theme.primaryColor}
                  onClick={() => {
                    exitIntentCallback && exitIntentCallback()
                    routeToQuotingTool && routeToQuotingTool()
                    resetAction()
                  }}
                >
                  Go to Quoting Tool
                </Button>
              )}
            {action.inputAction && action.inputAction.includes('idle-time') && (
              <Button
                width="100%"
                type="filled"
                primaryColor={theme.primaryColor}
                onClick={() => {
                  action.inputAction === 'idle-time-m101' &&
                    idleTimeCallbackM101 &&
                    idleTimeCallbackM101()
                  action.inputAction === 'idle-time-qt' &&
                    idleTimeCallbackQt &&
                    idleTimeCallbackQt()
                  resetAction()
                }}
              >
                {action.inputAction === 'idle-time-qt' && 'Call me'}
                {action.inputAction === 'idle-time-m101' && 'Call me'}
              </Button>
            )}
            {/* {action.inputAction && action.inputAction !== 'exitIntentConfirm' && (
            <Button
              width={action.inputAction ? '48%' : '100%'}
              type="outlined"
              onClick={resetAction}
            >
              Close
            </Button>
          )} */}
          </ButtonGroupDiv>
          <h4
            style={{
              textAlign: 'center',
              paddingTop: '36px',
              fontSize: '16px',
              lineHeight: '20px',
            }}
          >
            Need help? Give us a call at the number below
          </h4>

          <a
            href={`tel:${getContact()}`}
            style={{
              width: '100%',
              margin: '0',
              padding: '0',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              textDecoration: 'none',
            }}
            className="phone-number-fade"
            id="callInNum"
          >
            <img
              src={Telephone}
              alt="telephone"
              style={{ height: '20px', translate: '0px 1px' }}
            />
            <h3
              style={{
                textAlign: 'center',
                color: '#ba0000',
                margin: '0',
                paddingLeft: '5px',
                fontSize: '20px',
              }}
            >
              {getContact()}
            </h3>
          </a>
        </ModalContainer>
      </Wrapper>
    </Overlay>
  )
}

export default Popup
