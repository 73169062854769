import React from 'react'
import { fireVideoEvent } from '../utils/fireEvent'
import log from '../utils/log'

const useVideoTracking = () => {
  const seconds = React.useRef(0) // Keeps track of the number of seconds that the video has been playing.
  const currentVideo = React.useRef('') // Keeps track of the URL of the current video being played.
  const secondPlaying = React.useRef(0) // Keeps track of the second that the video is currently playing.

  /**
   * Function to track the number of seconds that a video has been playing and fire an event every 5 seconds to report the progress of the video.
    The useCallback hook takes two arguments: a function to memoize, and an array of dependencies that trigger the creation of a new memoized function when they change. In this case, the dependency array is empty, which means that the trackSeconds function is only created once and reused across renders.
   * @param videoname: video url
   * @returns
   */
  const trackSeconds = React.useCallback(
    (videoName: string) =>
      setInterval(() => {
        seconds.current += 1
        log('seconds: ', seconds)

        if (seconds.current % 5 === 0) {
          fireVideoEvent('trackVideo', {
            videoName,
            totalSeconds: seconds.current,
            intervalDuration: 5,
            round: +(seconds.current / 5),
            secondPlaying: secondPlaying.current,
          })
        }
      }, 1000),
    []
  )

  /**
   *trackSecondsRef is a variable that stores a reference to the trackSeconds function returned by the useCallback hook. 
   This reference is used to clear the timer created by setInterval from anywhere in the component by calling clearInterval(trackSecondsRef.current). 
   This helps ensure that only one timer is running at a time and that it's properly cleared when it's no longer needed.
   */
  const trackSecondsRef = React.useRef<any>(null)

  const startTimer = (newVideoName: string) => {
    if (newVideoName !== currentVideo.current) {
      currentVideo.current = newVideoName
      resetTimer()
    } else {
      clearTimer()
    }
    trackSecondsRef.current = trackSeconds(currentVideo.current)
  }

  const trackSecondPlaying = (currentSecond: number) => {
    if (currentSecond) {
      const roundedSecond = Math.round(currentSecond)
      secondPlaying.current = roundedSecond
    }
  }

  const clearTimer = () => {
    resetSecondPlaying()
    clearInterval(trackSecondsRef.current)
  }

  const resetTimer = () => {
    resetSecondPlaying()
    clearInterval(trackSecondsRef.current)
    seconds.current = 0
  }

  const resetSecondPlaying = () => {
    secondPlaying.current = 0
  }

  return {
    startTimer,
    trackSecondPlaying,
    clearTimer,
    resetTimer,
  }
}

export { useVideoTracking }
