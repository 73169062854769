type idleTimerType = {
  path: string
  timer: number
}[]

interface ConfigI {
  website: string
  idleTimer: idleTimerType
}

const config = {
  website: 'CRA',
  idleTimer: [],
}

const createConfig = (userConfig: ConfigI) => {
  config.website = userConfig.website
}

export {config, createConfig}
