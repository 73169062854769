import uniqid from 'uniqid'
import { config } from '../config/config'
import { isBrowser } from './common.utils'
import log from './log'

let isCrossUser = false

const getCrossUser = () => {
  const user = isBrowser()
    ? new URLSearchParams(window.location.search).get('user') || ''
    : ''

  if (user) {
    const idArray = user.split('$')

    localStorage.setItem('userId', idArray[0])
    localStorage.setItem('trackingId', idArray[1])

    isCrossUser = true
  }
}

const getSite = () =>
  isBrowser() ? window.location.href.split('/').slice(1, 3).join('') : null
const getLocation = () =>
  isBrowser() ? new URL(window.location.href).pathname || '/' : null

const getTrackingId = () => {
  let trackingId = localStorage.getItem('trackingId')
  if (!(trackingId && typeof trackingId === 'string')) {
    trackingId = uniqid()
    localStorage.setItem('trackingId', trackingId)
  }

  return trackingId
}

const getSessionId = () => {
  const sessionId = localStorage.getItem('sessionId')
  if (!sessionId) return '-'
  return sessionId
}

const getUserId = () => {
  let userId = '-'

  if (config.website === 'Quoting Tool') {
    const localUserId = localStorage.getItem('userId')
    if (localUserId && typeof localUserId === 'string') {
      userId = localUserId
    } else {
      const customerStore = localStorage.getItem('customerStore')
      if (customerStore && typeof customerStore === 'string') {
        userId = JSON.parse(
          localStorage.getItem('customerStore') as string
        ).username
        localStorage.setItem('userId', userId)
      }
    }
  }

  if (config.website === 'Aetna Medicare') {
    userId = localStorage.getItem('userId') || '-'
    log('Userid in Aetna Medicare: ', userId)
  }

  if (config.website === 'Mutual of Omaha') {
    userId = localStorage.getItem('userId') || '-'
    log('Userid in Mutual of Omaha: ', userId)
  }

  if (isBrowser()) (window as any).userId = userId
  return userId
}

const getCrossTrackUrl = (url: string) => {
  return url + '?user=' + getUserId() + '$' + getTrackingId()
}

export {
  getCrossUser,
  getSite,
  getLocation,
  getUserId,
  getTrackingId,
  getCrossTrackUrl,
  getSessionId,
  isCrossUser,
}
