import React from 'react';
import './src/styles/index.scss';
import { ActionContextProvider, createConfig } from './src/cra';
import addressSectionExitIntentUtils from './src/utils/addressSectionExitIntentUtils';
import Cookies from 'universal-cookie';
import BrandLogoContextProvider from './src/context/BrandLogoContextProvider'

export const wrapRootElement = ({ element }) => {
  createConfig({
    website: 'Demographics',
  });
  const cookies = new Cookies();
  cookies.remove('exit-intent', {path: "/", domain: window.location.hostname});
  return (
    <ActionContextProvider
      exitIntentCallback={addressSectionExitIntentUtils.exitIntentCallback}
      routeToQuotingTool={addressSectionExitIntentUtils.routeToQuotingTool}
    >
      <BrandLogoContextProvider>{element}</BrandLogoContextProvider>
    </ActionContextProvider>
  );
};
